<template>
    <div class="images-block-wrap">
        <div class="search-bar">
            <Dropdown
                :visible="visible"
                class="dropdown"
                trigger="custom"
                placement="bottom-start"
                transfer-class-name="jofee-label-dropdown"
                @on-clickoutside="handleVisible">
                <a href="javascript:void(0)" @click="handleVisible">
                    <Button class="label-more" type="default" size="large">
                        <i class="iconfont icon-gengduo"></i>
                    </Button>
                </a>
                <DropdownMenu slot="list">
                    <div class="category-label_parent">
                        <div :class="['category-label_parent_item pointer line-overflow', labelParent === 'all' ? 'active': '']" data-name="all" @click="handleClickAll">全部</div>
                        <div
                            v-for="item in labels"
                            :key="item.rowid"
                            :data-name="item.rowid"
                            :class="['category-label_parent_item pointer line-overflow', labelParent === item.rowid ? 'active': '']"
                            @click.stop="handleClickParent(item)">{{ item.name }}</div>
                    </div>
                    <div class="category-label_children">
                        <div
                            v-for="child in labelChildren"
                            :key="child.rowid"
                            :data-name="child.rowid"
                            :class="['category-label_children_item pointer line-overflow', labelChild === child.rowid ? 'active': '']"
                            @click="handleClickChild(child)">{{ child.name }}</div>
                    </div>
                </DropdownMenu>
            </Dropdown>
            <Input v-model="query" class="search" size="large" clearable :placeholder="placeholder" @keyup.native.enter="searchNew" @on-clear="searchNew" @paste.native="handleStop" @copy.native="handleStop" @cut.native="handleStop" @keydown.native="handleStop" @keyup.native="handleStop" >
                <Icon class="pointer" type="ios-search" slot="suffix" @click="searchNew" />
            </Input>
        </div>
        <load-more class="load-more-wrap" :onRefresh="handleLoadData" :disabled="isLoaded" height="calc(100% - 60px)">
            <ul class="images-block">
                <li
                    v-for="item in list"
                    :key="item.rowid"
                    class="image-item pointer"
                    @click="handleAddImage(item)">
                    <img :src="'/cym/'+item.uri+'/thumbnail'" class="image-center" >
                    <span class="type">{{ item.material_type.text._ }}</span>
                    <div class="name line-overflow" >{{ item.name }}</div>
                </li>
            </ul>
        </load-more>
    </div>
</template>
<script>
    import loadMore from '@/views/components/loadMore'

    export default {
        name: "personImages",
        mixins: [],
        components: {
            loadMore
        },
        props: {
        },
        data () {
            return {
                isLoaded: false,
                current: 1,
                pageSize: 12,
                total: 0,
                list: [],
                query: '',
                loading: false,
                uploadModalShow: false,
                labels: [],
                labelChildren: [],
                labelParent: 'all',
                labelChild: '',
                placeholder: '搜索素材',
                visible: false
            }
        },
        computed: {
            bucket_token() {
                return this.$store.state.default_bucket_tokens.user
            }
        },
        methods: {
            handleStop(e) {
                e.stopPropagation()
            },
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.list = []
                this.getData()
            },
            async handleLoadData(done) {
                // this.current += 1
                await this.getData()
                done()
            },
            getData() {
                let params = {
                    query: this.query,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize
                }
                if (this.labelChild) {
                    params.tag_id = this.labelChild
                }
                this.loading = true
                return this.$api.get('open_materials/search', { params }).then(({ data, total_count }) => {
                    if (data.length !== 0) {
                        this.current += 1
                        this.list.push(...data)
                        if (this.list.length == total_count) {
                            this.isLoaded = true
                        } else {
                            this.isLoaded = false
                        }
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            handleLabelList(label) {
                this.current = 1
                this.label = label
                this.list = []
                this.getData()
            },
            handleAddImage(item) {
                this.$emit('handleAddImage', item)
            },
            handleClickParent(item) {
                this.labelParent = item.rowid
                this.labelChildren = item.children
            },
            handleClickAll() {
                if (this.labelParent === 'all') return
                this.current = 1
                this.list = []
                this.labelParent = 'all'
                this.labelChildren = []
                this.placeholder = '搜索素材'
                this.visible = false
                this.labelChild = ''
                this.getData()
            },
            handleClickChild(item) {
                if (this.labelChild === item.rowid) return
                this.current = 1
                this.list = []
                this.labelChild = item.rowid
                this.placeholder = `在${item.name}中搜索`
                this.visible = false
                this.getData()
            },
            getLabelData() {
                this.$api.get('open_materials/tags').then(({ data }) => {
                    this.labels = data.filter((item) => {
                        if (item.children && item.children.length) return item
                    })
                }).catch(() => {})
            },
            handleVisible() {
                this.visible = !this.visible
            }
        },
        created() {
            this.getData()
            this.getLabelData()
        }
    }
</script>
<style scoped lang="less">
@import "~less/color";
@deep: ~'>>>';

.images-block-wrap {
    padding: 10px 0 0 0;
    height: 100%;
}
.search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    .upload-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 7px;
        width: 39px;
        height: 41px;
        background: @primary-color;
        border-radius: 5px;
        color: #fff;
        .iconfont {
        }
    }
    .dropdown {
        position: relative;
        .close {
            display: none;
        }
        .label-mag {
            cursor: pointer;
            padding: 7px 0 7px 4px;
            font-size: 14px;
        }
        &:hover {
            .close {
                display: block;
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }
    }
    .search {
        width: 204px;
    }
}
.action-bar {
    margin-bottom: 10px;
    .dropdown-label {
        margin-left: -15px;
        .tooltip {
            display: block;
            width: 100%;
            .label {
                display: block;
                max-width: 179px;
            }
        }
    }
}
.load-more-wrap {
    overflow-x: hidden;
    width: 282px;
    .images-block {
        overflow: auto;
        width: 274px;
        .image-item {
            position: relative;
            display: inline-block;
            margin: 0 10px 10px 0;
            width: 127px;
            height: 148px;
            background: #cfcfcf;
            box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
            .type {
                position: absolute;
                right: 12px;
                top: 16px;
                height: 16px;
                padding: 0 5px;
                border-radius: 4px;
                background: #f8fbff;
                font-size: 12px;
                text-align: center;
                line-height: 16px;
            }
            .name {
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 0 5px;
                width: 100%;
                background: #666666;
                font-size: 14px;
                color: #ececec;
                text-align: center;
            }
        }
    }
}
.dropdown {
    padding: 0 0;
    .label-more {
        margin-right: 7px;
        padding: 0;
        width: 39px;
    }
    @{deep} .ivu-dropdown-menu {
        min-width: 266px;
    }
    @{deep} .jofee-label-dropdown {
        padding: 0 0;
    }
    .category-label_parent {
        display: inline-block;
        padding: 5px 0;
        width: 124px;
        border-right: 1px solid #d3d3d3;
        vertical-align: top;
        background: #f8fbff;
        .category-label_parent_item {
            padding: 0 18px;
            height: 28px;
            line-height: 28px;
        }
        .active {
            background: #fff;
            color: @primary-color;
        }
    }
    .category-label_children {
        display: inline-block;
        padding: 5px 0;
        width: 124px;
        vertical-align: top;
        .category-label_children_item {
            padding: 0 18px;
            height: 28px;
            line-height: 28px;
        }
        .active {
            background: #fff;
            color: @primary-color;
        }
    }
}
</style>