<template>
    <div class="images-block-wrap">
        <div class="search-bar">
            <div class="upload-button pointer" @click="handleUpload">
                <i class="iconfont icon-shangchuan"></i>
            </div>
            <Input v-model="query" class="search" size="large" clearable placeholder="搜索当前库" @keyup.native.enter="searchNew" @on-clear="searchNew" @paste.native="handleStop" @copy.native="handleStop" @cut.native="handleStop" @keydown.native="handleStop" @keyup.native="handleStop" >
                <Icon class="pointer" type="ios-search" slot="suffix" @click="searchNew" />
            </Input>
        </div>
        <div class="action-bar">
            <Dropdown class="dropdown dropdown-label" trigger="click" placement="bottom-start" >
                <Icon v-if="label" class="close" type="ios-close-circle" @click="clearLabelList" />
                <Button type="text">
                    标签<span v-if="label">:{{ label.name }}</span>
                    <Icon type="ios-arrow-down"></Icon>
                </Button>
                <DropdownMenu slot="list">
                    <load-more :onRefresh="handleReachBottom" :disabled="isLabelLoaded" height="200px">
                        <Tooltip class="tooltip" :content="item.name" theme="light" placement="top-start" transfer v-for="(item, index) in labelList" :key="index" :delay="1000">
                            <DropdownItem class="label line-overflow" @click.native="handleLabelList(item)">{{ item.name }}</DropdownItem>
                        </Tooltip>
                    </load-more>
                </DropdownMenu>
            </Dropdown>
        </div>
        <load-more class="load-more-wrap" :onRefresh="handleLoadData" :disabled="isLoaded" height="calc(100% - 113px)">
            <ul class="images-block">
                <li
                    v-for="item in list"
                    :key="item.rowid"
                    class="image-item pointer"
                    @click="handleAddImage(item)">
                    <img :src="'/cym/'+item.uri+'/thumbnail'" class="image-center" >
                    <span class="type">{{ item.material_type.text._ }}</span>
                </li>
            </ul>
        </load-more>
        <uploadModal
            ref="upload"
            :show="uploadModalShow"
            :bucket_token="bucket_token"
            :formats="formats"
            @confirm="handleUploadConfirm"
            @cancel="handleUploadCancel"
            @show="handleUploadModal"
            @refresh="handleRefreshLabel"
        ></uploadModal>
    </div>
</template>
<script>
    import loadMore from '@/views/components/loadMore'
    import uploadModal from '@/views/components/upload'
    import { dropdownTree } from '@/mixins/index'

    export default {
        name: "companyImages",
        mixins: [dropdownTree],
        components: {
            loadMore,
            uploadModal
        },
        props: {
            is_org: {
                default: ''
            },
            formats: {
                default: () => {
                    return []
                }
            }
        },
        data () {
            return {
                isLabelLoaded: false,
                isLoaded: false,
                current: 1,
                pageSize: 12,
                total: 0,
                list: [],
                query: '',
                loading: false,
                label: null,
                labelList: [],
                labelCurrent: 1,
                labelPageSize: 10,
                uploadModalShow: false,
            }
        },
        computed: {
            bucket_token() {
                return this.$store.state.default_bucket_tokens.org
            },
            tree_data() {
                return [this.$store.state.up_tree]
            }
        },
        methods: {
            handleStop(e) {
                e.stopPropagation()
            },
            handleUpload() {
                if (this.$refs['upload'].defaultList.length > 0) {
                    this.$refs['upload'].loadingModalShow = true
                    this.$emit('handleDisableKeyboard', true)
                    return
                }
                this.$el.querySelector('.upload').getElementsByTagName('input')[0].click()
                this.$refs['upload'].init = true
            },
            handleUploadModal() {
                this.uploadModalShow = true
                this.$emit('handleDisableKeyboard', true)
            },
            handleUploadConfirm() {
                this.handleUploadCancel()
                this.current = 1
                this.list = []
                this.getData()
            },
            handleUploadCancel() {
                this.uploadModalShow = false
                this.$emit('handleDisableKeyboard', false)
            },
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.list = []
                this.getData()
            },
            async handleLoadData(done) {
                // this.current += 1
                await this.getData()
                done()
            },
            getData() {
                let params = {
                    type_list: JSON.stringify(['image', 'video']),
                    query: this.query,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize
                }
                if (this.label) {
                    params.label_id = this.label.rowid
                }

                this.treeList = []
                this.getTreeList(this.tree_data, 'bucket_token')
                params.bucket_token_list = JSON.stringify(this.treeList)
                this.loading = true
                return this.$api.get('materials/search', { params }).then(({ data, total_count }) => {
                    if (data.length !== 0) {
                        this.current += 1
                        this.list.push(...data)
                        if (this.list.length == total_count) {
                            this.isLoaded = true
                        } else {
                            this.isLoaded = false
                        }
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            getLabelList() {
                let params = {
                    bucket_token: this.is_org,
                    start: (this.labelCurrent - 1) * this.labelPageSize,
                    count: this.labelPageSize
                }
                return this.$api.get('labels/list', { params }).then(({ data, total_count }) => {
                    if (data.length !== 0) {
                        this.labelCurrent += 1
                        this.labelList.push(...data)
                        if (this.labelList.length === total_count) {
                            this.isLabelLoaded = true
                        } else {
                            this.isLabelLoaded = false
                        }
                    }
                })
            },
            async handleReachBottom(done) {
                await this.getLabelList()
                done()
            },
            handleLabelList(label) {
                this.current = 1
                this.label = label
                this.list = []
                this.getData()
            },
            clearLabelList() {
                this.current = 1
                this.label = ''
                this.list = []
                this.getData()
            },
            handleRefreshLabel() {
                this.label = null
                this.labelCurrent = 1
                this.labelList =  []
                this.getLabelList()
            },
            handleAddImage(item) {
                this.$emit('handleAddImage', item)
            }
        },
        created() {
            this.getData()
            this.getLabelList()
        }
    }
</script>
<style scoped lang="less">
@import "~less/color";

.images-block-wrap {
    padding: 10px 0 0 0;
    height: 100%;
}
.search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    .upload-button {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 7px;
        width: 39px;
        height: 41px;
        background: @primary-color;
        border-radius: 5px;
        color: #fff;
        .iconfont {
        }
    }
    .search {
        width: 204px;
    }
}
.action-bar {
    margin-bottom: 10px;
    .dropdown {
        position: relative;
        .close {
            display: none;
        }
        .label-mag {
            cursor: pointer;
            padding: 7px 0 7px 4px;
            font-size: 14px;
        }
        &:hover {
            .close {
                display: block;
                position: absolute;
                right: 0px;
                top: 0px;
            }
        }
    }
    .dropdown-label {
        margin-left: -15px;
        .tooltip {
            display: block;
            width: 100%;
            .label {
                display: block;
                max-width: 179px;
            }
        }
    }
}
.load-more-wrap {
    overflow-x: hidden;
    width: 282px;
    .images-block {
        overflow: auto;
        width: 274px;
        .image-item {
            position: relative;
            display: inline-block;
            margin: 0 10px 10px 0;
            width: 127px;
            height: 148px;
            background: #cfcfcf;
            box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
            .type {
                position: absolute;
                right: 12px;
                top: 16px;
                height: 16px;
                padding: 0 5px;
                border-radius: 4px;
                background: #f8fbff;
                font-size: 12px;
                text-align: center;
                line-height: 16px;
            }
        }
    }
}
</style>