<template>
    <div class="tmpl-block-wrap">
        <div class="search-bar">
            <Dropdown class="dropdown dropdown-label" trigger="click" placement="bottom-start" >
                <Icon v-if="label" class="close" type="ios-close-circle" @click="clearLabelList" />
                <Button class="btn" type="default" size="large">
                    标签<span v-if="label" class="label line-overflow">:{{ label.name }}</span>
                    <Icon type="ios-arrow-down"></Icon>
                </Button>
                <DropdownMenu slot="list">
                    <load-more :onRefresh="handleReachBottom" :disabled="isLabelLoaded" height="200px">
                        <Tooltip class="tooltip" :content="item.name" theme="light" placement="top-start" transfer v-for="(item, index) in labelList" :key="index" :delay="1000">
                            <DropdownItem class="label line-overflow" @click.native="handleLabelList(item)">{{ item.name }}</DropdownItem>
                        </Tooltip>
                    </load-more>
                </DropdownMenu>
            </Dropdown>
            <Input v-model="query" class="search" size="large" clearable placeholder="搜索模板" @keyup.native.enter="searchNew" @on-clear="searchNew" @paste.native="handleStop" @copy.native="handleStop" @cut.native="handleStop" @keydown.native="handleStop" @keyup.native="handleStop" >
                <Icon class="pointer" type="ios-search" slot="suffix" @click="searchNew" />
            </Input>
        </div>
        <load-more class="load-more-wrap" :onRefresh="handleLoadData" :disabled="isLoaded" height="calc(100% - 60px)">
            <ul class="templates-block">
                <li
                    v-for="item in list"
                    :key="`${item.rowid}`"
                    class="template-item pointer"
                    @click="handleReplaceTmpl(item)">
                    <img :src="`/cym/${item.uri}/thumbnail`" :class="['image-center', item.material_type.material_type==='template'?'template-preview-image-bkg':'']" >
                </li>
            </ul>
        </load-more>
    </div>  
</template>
<script>
    import loadMore from '@/views/components/loadMore'
    import { dropdownTree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [dropdownTree],
        components: {
            loadMore
        },
        props: {
            data_type: {
                default: ''
            }
        },
        data () {
            return {
                isLabelLoaded: false,
                isLoaded: false,
                current: 1,
                pageSize: 12,
                total: 0,
                list: [],
                query: '',
                loading: false,
                label: null,
                labelList: [],
                labelCurrent: 1,
                labelPageSize: 10,
            }
        },
        computed: {
            bucket_token() {
                return this.$store.state.default_bucket_tokens.user
            },
        },
        methods: {
            handleStop(e) {
                e.stopPropagation()
            },
            async handleReachBottom(done) {
                await this.getLabelList()
                done()
            },
            getLabelList() {
                let params = {
                    bucket_token: this.bucket_token,
                    start: (this.labelCurrent - 1) * this.labelPageSize,
                    count: this.labelPageSize
                }

                return this.$api.get('labels/list', { params }).then(({ data, total_count }) => {
                    if (data.length !== 0) {
                        this.labelCurrent += 1
                        this.labelList.push(...data)
                        if (this.labelList.length === total_count) {
                            this.isLabelLoaded = true
                        } else {
                            this.isLabelLoaded = false
                        }
                    }
                })
            },
            handleLabelList(label) {
                this.current = 1
                this.label = label
                this.list = []
                this.getData()
            },
            clearLabelList() {
                this.current = 1
                this.label = ''
                this.list = []
                this.getData()
            },
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.list = []
                this.getData()
            },
            async handleLoadData(done) {
                await this.getData()
                done()
            },
            getData() {
                let params = {
                        bucket_token: this.bucket_token,
                        query: this.query,
                        state: this.state,
                        start: (this.current - 1) * this.pageSize,
                        count: this.pageSize,
                        type_list: this.data_type==='static'?'template':'data_template'
                    };

                params.label_id = this.label && this.label.rowid

                this.$api.get('materials/search', { params }).then(({ data, total_count, page }) => {
                    if (page === this.current) {
                        if (data.length !== 0) {
                            this.current += 1
                        }
                        this.list.push(...data)
                        if (this.list.length == total_count) {
                            this.isLoaded = true
                        } else {
                            this.isLoaded = false
                        }
                    }
                }).finally(() => {
                    this.loading = false
                })
            },
            handleReplaceTmpl(item) {
                let params = {
                    tmplType: 'space',
                    item: item
                }
                this.$emit('handleReplaceTmpl', params)
            }
        },
        mounted() {
            this.getLabelList()
            this.getData()
        }
    }
</script>
<style scoped lang="less">
.tmpl-block-wrap {
    padding: 10px 0 0 0;
    height: 100%;
    .search-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 16px;
        .dropdown {
            position: relative;
            .btn {
                .label {
                    vertical-align: middle;
                    display: inline-block;
                    max-width: 40px;
                }
            }
            .close {
                display: none;
            }
            &:hover {
                .close {
                    display: block;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
            }
        }
        .dropdown-label {
            .tooltip {
                display: block;
                width: 100%;
                .label {
                    display: block;
                    max-width: 179px;
                }
            }
        }
        .search {
            width: 204px;
        }
        .dropdown-label {
            margin-right: 10px;
        }
    }
    .load-more-wrap {
        overflow-x: hidden;
        width: 282px;
        .templates-block {
            overflow: auto;
            width: 274px;
            .template-item {
                position: relative;
                display: inline-block;
                margin: 0 10px 10px 0;
                width: 127px;
                height: 148px;
                background: #cfcfcf;
                box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
            }
        }
    }
}
</style>