<template>
    <div class="templates-block-wrap">
        <ul class="template-type-wrap">
            <li v-if="spaceType==='company'" :class="['template-type-item', 'pointer', tmplType==='company'?'template-type-item-active':'']" data-name="company" @click="handleTmplType">企业空间</li>
            <li v-if="spaceType==='person'" :class="['template-type-item', 'pointer', tmplType==='person'?'template-type-item-active':'']" data-name="person" @click="handleTmplType">个人空间
            </li>
            <li :class="['template-type-item', 'pointer', tmplType==='admin'?'template-type-item-active':'']" data-name="admin" @click="handleTmplType">{{ data_type==='static'?'精选模板':'AI模板' }}</li>
        </ul>
        <div class="components-wrap">
            <particular-tmpl
                v-if="tmplType==='admin'"
                :data_type="data_type"
                @handleReplaceTmpl="handleReplaceTmpl"
                @handleDisableKeyboard="handleDisableKeyboard"></particular-tmpl>
            <person-tmpl
                v-if="tmplType==='person'"
                :data_type="data_type"
                @handleReplaceTmpl="handleReplaceTmpl"
                @handleDisableKeyboard="handleDisableKeyboard"></person-tmpl>
            <company-tmpl
                v-if="tmplType==='company'"
                :is_org="is_org"
                :data_type="data_type"
                @handleReplaceTmpl="handleReplaceTmpl"
                @handleDisableKeyboard="handleDisableKeyboard"></company-tmpl>
        </div>
    </div>  
</template>
<script>
    import particularTmpl from './particularTmpl'
    import personTmpl from './personTmpl'
    import companyTmpl from './companyTmpl'

    export default {
        name: "",
        mixins: [],
        components: {
            particularTmpl,
            personTmpl,
            companyTmpl
        },
        props: {
            is_org: {
                default: ''
            },
            data_type: {
                default: ''
            },
            spaceType: {
                default: ''
            }
        },
        data () {
            return {
                query: '',
                isLoaded: false,
                loading: false,
                current: 1,
                pageSize: 12,
                list: [],
                tmplType: '',
                isLabelLoaded: false,
                label: null,
                labelList: [],
                labelCurrent: 1,
                labelPageSize: 10,
            }
        },
        computed: {
            bucket_token() {
                return this.$store.state.default_bucket_tokens.user
            },
            tree_data() {
                return [this.$store.state.up_tree]
            }
        },
        watch: {
            spaceType: {
                handler: function(val) {
                    this.tmplType = val
                },
                immediate: true
            }
        },
        methods: {
            handleTmplType(e) {
                let name = e.currentTarget.dataset.name
                if (name === this.tmplType) return
                this.tmplType = name
            },
            handleReplaceTmpl(params) {
                this.$emit('confirm', params)
            },
            handleDisableKeyboard(value) {
                this.$emit('handleDisableKeyboard', value)
            }
        },
        mounted() {
            // this.getData()
        }
    }
</script>
<style scoped lang="less">
.templates-block-wrap {
    padding: 10px 0 0 16px;
    height: 100%;
    .template-type-wrap {
        overflow: hidden;
        margin: 8px 0 17px;
        border: 1px solid #cccccc;
        width: calc(100% - 16px);
        height: 40px;
        border-radius: 5px;
        font-size: 14px;
        text-align: center;
        .template-type-item {
            display: inline-block;
            width: 50%;
            height: 100%;
            background: #fff;
            line-height: 40px;
        }
        .template-type-item-active {
            background: #f1f2f4;
        }
    }
    .components-wrap {
        height: calc(100% - 65px);
    }
    .search-bar {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .dropdown {
            position: relative;
            .btn {
                .label {
                    vertical-align: middle;
                    display: inline-block;
                    max-width: 40px;
                }
            }
            .close {
                display: none;
            }
            &:hover {
                .close {
                    display: block;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
            }
        }
        .dropdown-label {
            .tooltip {
                display: block;
                width: 100%;
                .label {
                    display: block;
                    max-width: 179px;
                }
            }
        }
        .dropdown-label {
            margin-right: 10px;
        }
    }
    .load-more-wrap {
        overflow-x: hidden;
        width: 282px;
        .templates-block {
            overflow: auto;
            width: 274px;
            .template-item {
                position: relative;
                display: inline-block;
                margin: 0 10px 10px 0;
                width: 127px;
                height: 148px;
                background: #cfcfcf;
                box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
            }
        }
    }
}
</style>