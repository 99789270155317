import { render, staticRenderFns } from "./companyTmpl.vue?vue&type=template&id=6a5876db&scoped=true&"
import script from "./companyTmpl.vue?vue&type=script&lang=js&"
export * from "./companyTmpl.vue?vue&type=script&lang=js&"
import style0 from "./companyTmpl.vue?vue&type=style&index=0&id=6a5876db&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a5876db",
  null
  
)

export default component.exports